import {
  REGISTER_SUCCESS,
  VALIDATE_USER,
  INVALIDATE_USER,
  VALIDATE_SUCCESS,
  VALIDATE_FAIL,
  VALIDATE_EMAIL,
  REGISTER_FAIL,
  USER_LOADED,
  USER_NOT_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "./typesAuth";

const authReducer = (state, action) => {
  switch (action.type) {
    case VALIDATE_SUCCESS:
      return {
        ...state,
        isValidated: action.payload.isValidated,
        loading: false,
        // user: action.payload.user,
      };
    case VALIDATE_FAIL:
      return {
        ...state,
        isValidated: false,
        loading: false,
        // user: action.payload.user,
      };
    // case VALIDATE_EMAIL:
    //   return {
    //     ...state,
    //     // isValidated: action.payload.isValidated,
    //     loading: false,
    //     user: action.payload,
    //   };

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      // store token in local storage
      //   and in context through ...action.payload
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case USER_LOADED:
      // loads users (post login, register etc.)
      //   and checks if validated from DB
      return {
        ...state,
        isAuthenticated: true,
        isValidated: action?.payload?.isValidated ?? false,
        loading: false,
        user: action.payload,
      };
    case USER_NOT_LOADED:
      return {
        ...state,
        isAuthenticated: false,
        isValidated: false,
        loading: false,
        token: null,
        user: null,
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isValidated: false,
        loading: false,
        user: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default authReducer;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { formatCtlDate } from "../../utils/functionsCommon";

const radioItem = (handlerFunc, inputId, unit, value, action) => {
  let opts;
  if ((value === undefined || value === "") && unit === "kg") {
    opts = { checked: true };
  } else {
    opts = { checked: unit === value };
  }

  return (
    <div className="form-check form-check-inline">
      <input
        {...opts}
        className="form-check-input"
        type="radio"
        name={inputId}
        key={inputId}
        id={"radio-" + unit}
        value={unit}
        onChange={(e) => handlerFunc({ action: action, e: e }, inputId)}
      />
      <label className="form-check-label" htmlFor={"radio-" + unit}>
        {unit}
      </label>
    </div>
  );
};

const InputField = ({
  name,
  inputId,
  value,
  handlerFunc,
  type,
  what,
  duplicateField,
  options,
}) => {
  if (type === "date") {
    value = type === "date" && value ? formatCtlDate(value) : value;
  }
  let action = "update" + (what ? "-" + what : "");
  let placeholder = "";

  // used if the same field is used multiple times
  let controlId = duplicateField ? inputId + "." + duplicateField : inputId;

  return type === "select" ? (
    <Fragment>
      <select
        className="form-select"
        name={controlId}
        key={controlId}
        aria-label={name}
        value={value}
        onChange={(e) => handlerFunc({ action: action, e: e }, inputId)}
      >
        {options.map((option, idx) => (
          <option value={option} key={[controlId, idx].join("-")}>
            {option}
          </option>
        ))}
      </select>
    </Fragment>
  ) : name === "unit" ? (
    <Fragment>
      {radioItem(handlerFunc, controlId, "kg", value, action)}
      {radioItem(handlerFunc, controlId, "lb", value, action)}
    </Fragment>
  ) : (
    <input
      type={type}
      className="form-control"
      placeholder={placeholder}
      aria-label={name}
      aria-describedby="text field"
      name={controlId}
      key={controlId}
      value={value}
      onChange={(e) => handlerFunc({ action: action, e: e }, inputId)}
    />
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  inputId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handlerFunc: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  what: PropTypes.string.isRequired,
  duplicateField: PropTypes.number,
  options: PropTypes.array,
};
export default InputField;

// AUTH CONSTS
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const VALIDATE_USER = "VALIDATE_USER";
export const INVALIDATE_USER = "INVALIDATE_USER";
export const VALIDATE_SUCCESS = "VALIDATE_SUCCESS";
export const VALIDATE_FAIL = "VALIDATE_FAIL";
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const USER_NOT_LOADED = "USER_NOT_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import InputField from "../common/InputField";
import {
  completedButton,
  deleteSetButton,
  linkSetButton,
} from "../common/Buttons";

const SetItem = ({ set, updateFunction, edit, setId, idx, deletePressed }) => {
  let k = idx;

  let ctls = ["reps", "weight", "unit"];
  let rowClass = set?.completed ? "bg-success text-white" : "";
  return (
    <Fragment>
      <div
        className={"row py-1 border-bottom " + rowClass}
        key={k + "-exercise-r1"}
      >
        <div className="col-1 my-auto" key={k + "-exercise-r1c1"}>
          {set.no}.
        </div>
        {ctls.map((ctl, cIdx) => {
          let inputId = setId + "." + ctl + "." + cIdx;
          return (
            <div
              className={"col-2 my-auto text-center "}
              key={k + "-exercise-r2c2" + cIdx}
            >
              {!edit ? (
                set[ctl]
              ) : (
                <InputField
                  name={ctl}
                  inputId={inputId}
                  value={set[ctl]}
                  handlerFunc={updateFunction}
                  type={"text"}
                  what={"set"}
                  duplicateField={set?.duplicateField}
                />
              )}
            </div>
          );
        })}
        <div className="col-5 text-end" key={k + "-exercise-r1c3"}>
          {completedButton(
            { func: updateFunction },
            setId,
            set?.completed ?? false,
            "set"
          )}
          {deleteSetButton(
            updateFunction,
            k,
            setId,
            deletePressed,
            set?.completed
          )}
          {linkSetButton(updateFunction, k, setId, set?.link, set?.completed)}{" "}
          {/* {linkButton(set?.completed)}{" "} */}
        </div>
      </div>
    </Fragment>
  );
};

SetItem.propTypes = {
  set: PropTypes.object.isRequired,
  updateFunction: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  setId: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  deletePressed: PropTypes.object,
};
export default SetItem;

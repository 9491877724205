// SESSIONS CONSTS
export const GET_SESSION = "GET_SESSION";
export const GET_SESSION_COUNT = "GET_SESSION_COUNT";
export const GET_SESSION_TOTALS = "GET_SESSION_TOTALS";
export const GET_TOTALS_DAYSDUE = "GET_TOTALS_DAYSDUE";
export const GET_TOTALS = "GET_TOTALS";
export const ADD_SESSION = "ADD_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const SET_CURRENT_SESSION = "SET_CURRENT_SESSION";
export const CLEAR_CURRENT_SESSION = "CLEAR_CURRENT_SESSION";
export const SET_DELETE_SESSION = "SET_DELETE_SESSION";
export const CLEAR_DELETE_SESSION = "CLEAR_DELETE_SESSION";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const FILTER_SESSION = "FILTER_SESSION";
export const CLEAR_FILTER_SESSION = "CLEAR_FILTER_SESSION";

export const GET_SESSIONS = "GET_SESSIONS";
export const DELETE_SESSIONS = "DELETE_SESSIONS";
export const UPDATE_SESSIONS = "UPDATE_SESSIONS";

export const SESSION_ERROR = "SESSION_ERROR";
export const IMPORT_SESSION = "IMPORT_SESSION";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_SESSION_ALERT = "SET_SESSION_ALERT";
export const REMOVE_SESSION_ALERT = "REMOVE_SESSION_ALERT";
export const REMOVE_ALL_SESSION_ALERTS = "REMOVE_ALL_SESSION_ALERTS";

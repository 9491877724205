import React, { useReducer } from "react";
import axios from "axios";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import setAuthToken from "../../utils/setAuthToken";
import PropTypes from "prop-types";
import { configJSON, dbMsg, dbMsgLarge } from "../../utils/functionsCommon";

import {
  REGISTER_SUCCESS,
  VALIDATE_USER,
  INVALIDATE_USER,
  VALIDATE_EMAIL,
  VALIDATE_FAIL,
  REGISTER_FAIL,
  USER_LOADED,
  USER_NOT_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "./typesAuth";

let debug = 0;
let db = debug >= 1;
let dbl = debug;
let dp = "com.state.auth";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    isValidated: null,
    loading: true,
    user: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const validateUser = async (user, code) => {
    let lm = dp + ".validateUser: ";

    try {
      dbMsg(1, dbl, lm + "Starting");

      // try registering user with form data and json config
      dbMsg(1, dbl, lm + "Validating code with API");
      const res = await axios.post(
        "/api/users/validate",
        { user, code },
        configJSON
      );
      dbMsgLarge(1, dbl, lm + "User Validation result:-", res?.data);

      if (res?.data) {
        loadUser();
      } else {
        dbMsg(1, dbl, lm + "Other Issue");
        dispatch({
          type: VALIDATE_FAIL,
          payload: res.data,
        });
      }
      dbMsg(1, dbl, lm + "Finish");
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
        payload: err.msg,
      });
    }
  };

  const sendValidationEmail = async (user) => {
    let lm = dp + ".sendValidationEmail: ";

    try {
      dbMsg(1, dbl, lm + "Starting");

      // try registering user with form data and json config
      dbMsg(1, dbl, lm + "Validating code with API");
      const res = await axios.post(
        "/api/users/validateemail",
        { user },
        configJSON
      );
      console.log(res?.data);
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
        payload: err.msg,
      });
    }
  };

  // Load User
  const loadUser = async () => {
    let lm = dp + ".loadUser: ";

    // try registering user with form data and json config
    dbMsg(1, dbl, lm + "Starting");

    // if token exists, set token as default in axios config
    if (localStorage.token) {
      dbMsg(1, dbl, lm + "Token Exists");
      setAuthToken(localStorage.token);
    }

    try {
      // try getting user from auth
      dbMsg(1, dbl, lm + "Getting user data from auth");
      const res = await axios.get("/api/auth");
      dbMsgLarge(1, dbl, lm + "User data result:-", res?.data);

      if (!res.data) {
        // send user to reducer and mark as unauthenticated
        dbMsg(1, dbl, lm + "No User data provided, not loading user");
        dispatch({
          type: USER_NOT_LOADED,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as unauthenticated
        dbMsg(1, dbl, lm + "User data found, loading user to context");
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      }
      dbMsg(1, dbl, lm + "Finish");
    } catch (err) {
      dbMsgLarge(1, dbl, lm + "Error:-", err);
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Register User
  const register = async (formData) => {
    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/users", formData, configJSON);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      // load user and return user details
      loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: REGISTER_FAIL,
        payload: err.msg,
      });
    }
  };

  // Login User
  const login = async (formData) => {
    let lm = dp + ".login: ";

    // try registering user with form data and json config
    dbMsg(1, dbl, lm + "Starting login");
    dbMsg(1, dbl, lm + "Posting form for login");
    dbMsgLarge(1, dbl, lm + "formData:-", formData);
    await axios
      .post("/api/auth", formData, configJSON)
      .then((res) => {
        // send to reducer to log token in local storage
        //   and mark as authenticated
        dbMsgLarge(1, dbl, lm + "API result-", res?.data);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });

        // load user and return user details
        dbMsg(1, dbl, lm + "Load User");
        loadUser();
        dbMsg(1, dbl, lm + "Finish");
      })
      .catch((err) => {
        // remove token from storage and mark as not authenticated
        //   and some other stuff
        let errMsg = getError(err);
        dbMsgLarge(1, dbl, lm + "Error:-", errMsg);
        dispatch({
          type: LOGIN_FAIL,
          payload: errMsg.data.msg,
        });
      });
  };

  const getError = (error) => {
    let data;
    let status = 500;
    if (error.response) {
      // status code out of the range of 2xx
      data = error.response.data;
      status = error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      data = JSON.parse(error.request.response);
    } else {
      // Error on setting up the request
      data = error.message;
    }
    return { status, data };
  };

  // Logout User
  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  // Clear Errors
  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        isValidated: state.isValidated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        register,
        validateUser,
        sendValidationEmail,
        loadUser,
        login,
        logout,
        clearErrors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthState.propTypes = {
  children: PropTypes.object,
};

export default AuthState;

import { DateTime } from "luxon";

export const debugLevel = () => {
  return process.env?.REACT_APP_DEBUG ?? 0;
};

export const dbMsg = (lvl, dbl, msg) => {
  let includeTime = false;
  let dbgLevel = dbl === null || dbl === undefined ? 0 : dbl;
  // let dtStamp = DateTime.now().toFormat("yyyy-LL-dd hh:mm:ss,");
  let dtStamp = DateTime.now().toFormat("yyyy-LL-dd hh:mm:ss.SSS");
  msg = includeTime ? dtStamp + ": " + msg : msg;
  if (dbgLevel >= lvl) console.log(msg);
};

export const dbMsgLarge = (lvl, dbl, msg, data) => {
  let dbgLevel = dbl === null || dbl === undefined ? 0 : dbl;
  if (dbgLevel >= lvl) {
    if (msg) console.log(msg);
    if (data) console.log(data);
  }
};

export const configJSON = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const agoDateString = (dateCompare) => {
  if (!dateCompare.isValid) return dateCompare;

  let units = [
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
  ];
  let dateNew = DateTime.now();
  let diff = dateNew.diff(dateCompare, units);
  let agoString;

  for (let i = 0; i < units.length; i++) {
    let unit = units[i];
    if (diff[unit] >= 1) {
      let value = diff[unit];
      let unitString = value >= 2 ? unit : unit.substring(0, unit.length - 1);
      agoString = Math.round(value) + " " + unitString + " ago";
      return agoString;
    }
  }
  return "moments ago";
};

export const fromISOToFmt = (date, format) => {
  if (!date) return "";
  let newDate = DateTime.fromISO(date);
  return !newDate.isValid ? "" : newDate.toFormat(format);
};

export const padTimes = (value) =>
  (value.toString().length === 1 ? "0" : "") + value.toString();

export const getExpiryTime = (timeDefault = 90) => {
  const time = new Date();
  return time.setSeconds(time.getSeconds() + timeDefault); // 10 minutes timer
};

export const getNowTime = () => {
  const time = new Date();
  return time.setSeconds(time.getSeconds());
};

export const debugValues = (args) => {
  for (const [key, value] of Object.entries(args)) {
    console.log(`${key}:`, value);
  }
};

export const formatDate = (value) =>
  (value = value ? DateTime.fromISO(value).toFormat("dd/LL/yy") : "");

export const formatCtlDate = (value) =>
  (value = value ? DateTime.fromISO(value).toFormat("yyyy-LL-dd") : "");

export const formatFloat = (value) => (value = value ? "$" + value : "");

export const cleanFilters = (filters) => {
  if (filters) {
    for (const property in filters)
      if (filters[property] === "" || filters[property] === null)
        delete filters[property];
  }
  return filters;
};

export const getRecords = (data, filtered, filterOn) => {
  if (filterOn) {
    return filtered?.length ? filtered : null;
  } else {
    return data !== null && data?.length ? data : null;
  }
};

export const getRecordCount = (data, filtered, filterOn) => {
  if (!data && !filtered) return "0";
  return (filterOn ? filtered.length + "/" : "") + data?.length?.toString();
};

export const formatDLDate = (date) => {
  let d = DateTime.fromISO(date);
  return d.isValid ? d.toFormat("dd-MMM-yy") : "";
};

export const fldNotExistNullOrEmptyStr = (fld) => {
  return fld === undefined || fld === null || fld === "";
};

export const fldUpdIfEmp = (fld, val) =>
  fldNotExistNullOrEmptyStr(fld) ? val : fld;

export const changeCase = (text, textCase) => {
  let newText;
  switch (textCase.toLowerCase()) {
    case "upper":
      return text.toUpperCase();
    case "lower":
      return text.toLowerCase();
    case "sentence":
      text = text.split(".");
      newText = text.map(
        (t) => t.substr(0, 1).toUpperCase() + t.substr(1, t.length)
      );
      return newText.join(".");
    case "title":
      text = text.split(" ");
      newText = text.map((t) =>
        t.substr(0, 1).toUpperCase() + t.substr(1, t.length)
          ? t.substr(1, t.length)
          : ""
      );
      return newText.join(" ");
    default:
      return text;
  }
};

export const getValOr0 = (val) =>
  val !== undefined && val !== null ? parseFloat(val) : 0;

export const daysBetweenDates = (date1, date2) => {
  if (
    date1 === undefined ||
    date1 === null ||
    date2 === undefined ||
    date2 === null
  )
    return null;

  let startDate = DateTime.fromISO(date1);
  let endDate = DateTime.fromISO(date2);

  if (startDate.isValid && endDate.isValid) {
    if (startDate === endDate) return 0;
    return startDate < endDate
      ? DateTime.fromISO(startDate).diff(endDate, "days").days
      : DateTime.fromISO(endDate).diff(startDate, "days").days * -1;
  }

  return null;
};

export const getValidDate = (d) => {
  let newDate = DateTime.fromJSDate(d);
  if (!newDate.isValid) newDate = DateTime.fromISO(d);
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "dd-LLL-yyyy");
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "dd/LLL/yyyy");
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "yyyy-LLL-dd");
  return newDate;
};

export const returnISODate = (d) => {
  // let dateSeg = d.split('-');
  // let newDate = new Date(dateSeg[0], dateSeg[1], dateSeg[2]);
  // newDate += 5 * 24;

  let newDate = DateTime.fromISO(d);
  return newDate.toISO();
};

export const ShowValueOrName = (name, type, value) => {
  let v = formatValue(type, value);
  return v === null || v === "" ? name : v;
};

export const numStrToNumArr = (string) =>
  string.length === 0 ? [] : string.replace(/, +/g, ",").split(",").map(Number);

export const arrStrToNumArr = (arr) => arr.map((num) => parseInt(num));

export const today = () => DateTime.local();

export const confirmVal = (fld) =>
  fld !== undefined && fld !== null ? fld : null;

export const emptyArr = (arr) => !(arr && arr !== null && arr.length > 0);

export const emptyObj = (obj) => !obj || obj === null;

export const getNumber = (val) =>
  val !== undefined && val !== null ? parseFloat(val) : 0;

export const isNumeric = (num) => !isNaN(num);

export const isDate = (d) => {
  if (d !== undefined && d !== null) {
    let m = DateTime.fromISO(d);
    return m.isValid;
  } else {
    return false;
  }
};

// @name    getLastDayOfMonth
// @desc    return last day of month for passed year and month
export const getLastDayOfMonth = (y, m) => {
  return new Date(y, m + 1, 0).getDate();
};

// @name    getNextMonthDate
// @desc    return date for month + 1 of same day
export const getNextMonthDate = (d) => {
  var next_date = new Date(d);
  return new Date(next_date.setMonth(next_date.getMonth() + 1));
};

export const objHasKey = (obj, key) =>
  !emptyObj(obj) && Object.hasOwnProperty.call(obj, key);

export const findInObj = (arr, key, value) => arr.find((o) => o[key] === value);

export const findIndexInObj = (arr, key, value) =>
  arr.findIndex((o) => o[key] === value);

export const isObject = (value) =>
  value && typeof value === "object" && value.constructor === Object;

export const isObjectEmpty = (obj) => !(obj !== undefined && obj !== null);

export const isArrayEmpty = (obj) =>
  !(obj !== undefined && obj !== null && obj.length !== 0);

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const isArray = (value) =>
  value && typeof value === "object" && value.constructor === Array;

export const parsePassedDate = (d) => {
  let p = [
    [0, 4],
    [4, 6],
    [6, 8],
  ];
  let r = p.map((ps) => parseInt(d.toString().substring(ps[0], ps[1])));
  return new Date(r[0], r[1] - 1, r[2], 0, 0, 0);
};

export const numberFormat = (value, dec) =>
  value === null ? "" : parseFloat(value).toFixed(dec);

export const currencyFormat = (value, decs) =>
  value === null
    ? ""
    : new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
        maximumFractionDigits: decs,
        minimumFractionDigits: decs,
      }).format(value);

export const dateFormat = (value) => {
  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU").format(new Date(value))
    : "";
};

export const dateTimeFormat = (value) => {
  let format = {
    dateStyle: "short",
    timeStyle: "short",
  };

  format = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU", format).format(new Date(value))
    : "";
};

export const positionText = (position, pType) => {
  if (!pType) pType = "normal";

  let posTypes = {
    normal: ["left", "center", "right"],
    flex: ["start", "center", "end"],
  };

  switch (position.toLowerCase()) {
    case "left":
    case "start":
    case "begin":
    case 0:
    case "0":
    default:
      return posTypes[pType][0];
    case "middle":
    case "centre":
    case "center":
    case 1:
    case "1":
      return posTypes[pType][1];
    case "right":
    case "end":
    case 2:
    case "2":
      return posTypes[pType][2];
  }
};

export const getAlign = (field, pType) => {
  if (field?.align) return positionText(field.align, pType);
  if (!field?.type) field = { type: "string" };

  switch (field.type.toLowerCase()) {
    case "currency":
    case "float":
    case "number":
      return positionText("2", pType);
    case "date":
    case "datetime":
      return positionText("1", pType);
    case "string":
    default:
      return positionText("0", pType);
  }
};

export const formatValue = (type, value) => {
  if (value === undefined || value === null || value === "null" || value === "")
    return "";
  if (type === undefined || type === null || type === "") return value;

  switch (type.toLowerCase()) {
    case "date":
      return dateFormat(value);
    case "datetime":
      return dateTimeFormat(value);
    case "number":
      return numberFormat(value, 0);
    case "currency":
      return currencyFormat(value);
    case "float":
      return currencyFormat(value);
    case "array":
      return value.join(",");
    case "string":
    default:
      return value;
  }
};

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";
import Spinner from "../layout/Spinner";

const Validate = (props) => {
  // read from authorisation & alert contexts/states
  const authContext = useContext(AuthContext);
  const {
    user,
    validateUser,
    isValidated,
    isAuthenticated,
    sendValidationEmail,
    error,
    loading,
    clearErrors,
  } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  // this is used to return you to the page
  //   you originally were at or going to
  const location = useLocation();
  const history = useHistory();
  let { from } = location.state || { from: { pathname: "/" } };

  const [code, setCode] = useState(null);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (isValidated) {
      history.replace(from);
    }
    // if (!isAuthenticated) {
    //   history.replace(from);
    // }
    if (error === "Validation Failed") {
      setAlert(error, "warning");
      clearErrors();
    }

    // eslint-disable-next-line
  }, [error, isValidated, props.history, code]);

  // console.log("At validation page");
  // console.log(user);
  const updateCode = (e) => {
    e.preventDefault();
    setCode(e.target.value);
  };

  const resendEmail = (e) => {
    e.preventDefault();
    sendValidationEmail(user);
  };

  const validateCode = (e) => {
    e.preventDefault();
    validateUser(user, code);
    let a = attempts + 1;
    setAttempts(a);
    // setCode(null);
  };

  if (loading) return <Spinner />;

  let failedAttempt = attempts > 0 && isValidated === false;
  let inputColorClass = failedAttempt ? "bg-warning" : "";
  return (
    <div className="container">
      <div className="row ">
        <div className="col-12 p-1 m-1">
          <div className="h4">
            Thank you for registering {user?.name || "name"}.
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-12 p-1 m-1">
          <div>
            Your account needs to be validated. An activation email has been
            sent to your email account. Please login to your email, enter the
            code from the email below.
          </div>
        </div>
      </div>
      <div className="row ">
        <div className={`col-12 p-1 m-1 `}>
          <input
            className="form-control"
            name={"code"}
            id={"code"}
            type="text"
            onChange={(e) => updateCode(e)}
          />
          <button className="btn btn-primary" onClick={(e) => validateCode(e)}>
            Validate
          </button>
          {failedAttempt ? (
            <div className={`${inputColorClass}`}>
              Validation Code Incorrect
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row ">
        <div className="col-12 p-1 m-1">
          <div>The link will be valid for 20 mins</div>
          <div>
            {" "}
            <button className="btn btn-primary" onClick={(e) => resendEmail(e)}>
              Re-send Validation Email
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div></div>
          <div>Timer</div>
        </div>
      </div>
    </div>
  );
};

// Validate.propTypes = {
//   user: PropTypes.object,
// };

export default Validate;

import {
  addSet,
  deleteSet,
  deleteTheExercise,
  linkSets,
  updateSet,
  updateSetFields,
} from "../sessions/sessionFunctions";
import {
  dbMsg,
  dbMsgLarge,
  debugValues,
  getExpiryTime,
} from "../../utils/functionsCommon";

let dbl = 0;
let db = dbl >= 1;
let dp = "utils.sessionHandler";

const setCompleted = (eOrS, what, markCompleted) => {
  // don't updated if complete is already marked correctly
  if (eOrS?.completed === markCompleted) return eOrS;

  let visible = what === "set" ? {} : { visible: !markCompleted };
  // let visible = what === "set" ? {} : {};
  return {
    ...eOrS,
    ...visible,
    started: !eOrS?.started ? true : eOrS.started,
    startedWhen: !eOrS?.started ? new Date() : eOrS.startedWhen,
    completed: markCompleted,
    completedWhen: markCompleted ? new Date() : null,
  };
};

const updateSetsAsCompleted = (exercise, markCompleted) => {
  if (!exercise?.sets?.length) return [];
  return exercise.sets.map((set) => setCompleted(set, "set", markCompleted));
};

const allSetsDone = (exercise) => {
  let setsDone = exercise.sets.filter((set) => set.completed);
  return exercise?.sets?.length === setsDone.length ? true : false;
};

const isCompleted = (eOrS) =>
  eOrS?.completed === undefined ? false : eOrS.completed;

export const markCompleted = (exercise, setId, what, overrideValue) => {
  if (what === "exercise") {
    let wasCompleted = overrideValue ?? isCompleted(exercise);

    // update exercise as complete or not based on current value
    exercise = setCompleted(exercise, "exercise", !wasCompleted);

    // update all sets as complete or not based on exercise complete value
    exercise.sets = updateSetsAsCompleted(exercise, !wasCompleted);
  } else {
    let set = exercise.sets[setId];
    let wasCompleted = overrideValue ?? isCompleted(set);
    // mark set complete or not based on current set value
    set = setCompleted(set, "set", !wasCompleted);
    exercise.sets[setId] = set;

    // if all sets are complete, mark exercise as completed, if one set is not complete mark as
    let exerciseIsCompleted = allSetsDone(exercise);
    exercise = setCompleted(exercise, "exercise", exerciseIsCompleted);
  }
  return exercise;
};

export const setAllExercisesVisibility = (exercises, show) =>
  exercises.map((ex) => ({ ...ex, visible: show }));

export const confirmDeleteItem = (args, setOrExName) => {
  if (!args.deletePressed?.[setOrExName]) {
    args.setDeletePressed({ [setOrExName]: true });
    args.setAlert("Please click again to delete", "warning");
    return false;
  } else {
    args.setAlert("Deleted", "warning");
    args.setDeletePressed({});
    return true;
  }
};

export const createSetName = (exId, setId) =>
  ["exercise", exId, "set", setId].join(".");

export const changeCurrentSet = (exercise) => {
  let setToSet = {};
  if (exercise?.sets?.length) {
    // mark current set
    let notCompletedSets = exercise.sets.filter((set) => !set?.completed);
    if (notCompletedSets?.length) {
      setToSet = notCompletedSets[0];
    }
  }
  return setToSet;
};

const getNextSuperset = (exercises, exerciseId, setId) => {
  let lm = dp + ".getNextSuperset: ";
  dbMsg(3, dbl, lm + "Start");

  // check if superset and get goto the next set in the group
  dbMsg(3, dbl, lm + "Checking if superset and if last exercise");
  let exerciseCount = exercises?.length;
  let currentEx = exercises[exerciseId];
  let isSuperset = currentEx?.type === "superset" && currentEx?.supersetNo;
  let isLastExercise = exerciseId + 1 === exerciseCount;

  if (!isSuperset || isLastExercise) {
    dbMsg(3, dbl, lm + "Is not superset or is last exercise");
    return null;
  }

  if (!exercises?.[exerciseId] || !exercises?.[exerciseId]?.supersetNo) {
    dbMsg(3, dbl, lm + "Exercise or superset doesn't exists");
    return null;
  }

  dbMsg(3, dbl, lm + "Reading currentExercise & supersetId");
  let currentExercise = exercises[exerciseId];
  let supersetId = currentExercise.supersetNo;

  dbMsg(3, dbl, lm + "Looping through exercises for next superset");
  let nextSuperset = null;
  nextSuperset = exercises.find((exercise, i) => {
    // ignore current exercise or prior exercises
    if (i <= exerciseId) return false;

    // check superset and superset no. exist
    const { type, supersetNo } = exercise;

    // check if it's the same superset and the set isn't completed
    let sets = exercise.sets;
    let isSameSuperset = type === "superset" && supersetNo === supersetId;
    let isntCompletedSet =
      sets?.length > setId && sets[setId]?.completed !== true;

    if (isSameSuperset && isntCompletedSet) {
      dbMsg(3, dbl, lm + "Found next superset not completed");
      return true;
    }
  });
  if (nextSuperset) return nextSuperset;

  dbMsg(3, dbl, lm + "Didn't find next superset");
  return nextSuperset;
};

const getNextNotDoneSet = (exercises) => {
  if (!exercises?.length) return null;

  // if there is at least one not completed exercise, make it the next exercise
  let exNotCompleted = exercises.find((exercise) => {
    if (!exercise?.sets.length) return false;

    let setNotCompleted = exercise.sets.find((set) => !set?.completed);
    if (setNotCompleted) return true;
  });
  if (exNotCompleted) return exNotCompleted;
  return null;
};

const getExerciseFromLocalStorage = (exercises) => {
  let lm = dp + ".getExerciseFromLocalStorage: ";

  let exerciseCount = exercises?.length ?? 0;
  let activeExercise = localStorage.getItem("exercise");

  if (activeExercise) {
    dbMsg(0, dbl, lm + "Reading exercise from local storage");
    // if exercise is stored in local storage, use it
    let exerciseIdx = parseInt(activeExercise);
    // console.log(exerciseIdx);
    // console.log(exerciseCount);
    if (exerciseCount > exerciseIdx) {
      let setsNotCompleted = exercises[exerciseIdx].sets.filter(
        (e) => !e.completed
      );
      // // console.log(setsNotCompleted);
      // // console.log(setsNotCompleted?.length);
      // if (setsNotCompleted?.length) {
      //   console.log("found sets");
      // } else {
      //   console.log(" didn't find sets");
      // }
      if (setsNotCompleted?.length) return exercises[exerciseIdx];
    }
  }
  return null;
};

// used on refresh to display current exercise
//    either the one saved in local storage
//    or the first one not completed
export const changeCurrentExercise = (session, exerciseId, setId) => {
  let lm = dp + ".changeCurrentExercise: ";
  dbMsg(1, dbl, lm + "Start");

  if (!session?.exercises?.length) {
    dbMsg(1, dbl, lm + "No exercises exist");
    return null;
  }

  dbMsg(1, dbl, lm + "Reading exercises and storage");
  let nextExerciseSet = null;
  let exercises = session.exercises;

  // if (!nextExerciseSet) {
  //   // "checking for next superset"
  //   dbMsg(1, dbl, lm + "Checking for next superset");
  //   nextExerciseSet = getNextSuperset(exercises, exerciseId, setId);
  //   console.log(nextExerciseSet);
  // }

  // else check for the next exercise not completed
  if (!nextExerciseSet) {
    dbMsg(1, dbl, lm + "Getting next exercise not completed");
    nextExerciseSet = getNextNotDoneSet(exercises);
    dbMsgLarge(1, dbl, lm + "nextExerciseSet", nextExerciseSet);
  }
  // return current exercise or nothing
  dbMsg(1, dbl, lm + "Finish");
  return nextExerciseSet;
};

// used on refresh to display current exercise
//    either the one saved in local storage
//    or the first one not completed
export const setNextCurrentExercise = (session, exerciseId, setId) => {
  let lm = dp + ".setNextCurrentExercise: ";
  dbMsg(1, dbl, lm + "Start");

  if (!session?.exercises?.length) {
    dbMsg(1, dbl, lm + "No exercises exist");
    return null;
  }

  dbMsg(1, dbl, lm + "Checking for next superset");
  let nextExerciseSet = null;
  let exercises = session.exercises;
  nextExerciseSet = getNextSuperset(exercises, exerciseId, setId);
  console.log(nextExerciseSet);

  // else check for the next exercise not completed
  if (!nextExerciseSet) {
    dbMsg(1, dbl, lm + "Getting next exercise not completed");
    nextExerciseSet = getNextNotDoneSet(exercises);
    console.log(nextExerciseSet);
  }
  // return current exercise or nothing
  dbMsg(1, dbl, lm + "Finish");
  return nextExerciseSet;
};

// export const setNextCurrentExercise = (session, exerciseId, setId) => {
//   let lm = dp + ".setNextCurrentExercise: ";
//   dbMsg(1, dbl, lm + "Start");

//   if (!session?.exercises?.length) {
//     dbMsg(1, dbl, lm + "No exercises exist");
//     return null;
//   }
//   // set the active session being completed
//   //    first read if the exercise ID was stored as the last accessed, saved into localstorage
//   //    second default to the first not completed exercise
//   //    last default the first exercise

//   dbMsg(1, dbl, lm + "Reading exercises and storage");
//   let nextExerciseSet = null;
//   let exercises = session.exercises;
//   let exerciseCount = exercises.length;
//   let activeExercise = localStorage.getItem("exercise");

//   if (activeExercise) {
//     dbMsg(1, dbl, lm + "Reading exercise from local storage");
//     // if exercise is stored in local storage, use it
//     let exerciseIdx = parseInt(activeExercise);
//     if (exerciseCount > exerciseIdx) nextExerciseSet = exercises[exerciseIdx];
//   } else {
//     let currentEx = exercises[exerciseId];

//     // check if superset and get goto the next set in the group
//     dbMsg(1, dbl, lm + "Checking if superset and if last exercise");
//     let isSuperset = currentEx?.type === "superset" && currentEx?.supersetNo;
//     let isLastExercise = exerciseId + 1 === exerciseCount;

//     // "checking for next superset"
//     if (isSuperset && !isLastExercise) {
//       dbMsg(1, dbl, lm + "Is superset and not last exercise");
//       nextExerciseSet = getNextSuperset(exercises, exerciseId, setId);
//     }

//     // else check for the next exercise not completed
//     if (!nextExerciseSet) {
//       dbMsg(1, dbl, lm + "Getting next exercise not completed");
//       nextExerciseSet = getNextNotDoneSet(exercises);
//     }
//   }
//   // return current exercise or nothing
//   dbMsg(1, dbl, lm + "Finish");
//   return nextExerciseSet;
// };

// used when the last exercise is finished to find the next exercise
//    to display in the current exercise highlight
export const setNextExerciseSet = (args, session, exerciseId, setId) => {
  let lm = dp + ".setNextExerciseSet: ";
  dbMsg(1, dbl, lm + "Start");

  if (!args?.setCurrentExercise || !args.setCurrentSet) {
    dbMsg(1, dbl, lm + "Error: setCurrent functions not passed");
    return false;
  }

  dbMsg(1, dbl, lm + "Changing current exercise to next exercise");
  // let exercise = changeCurrentExercise(session, exerciseId, setId);
  let exercise = setNextCurrentExercise(session, exerciseId, setId);
  args.setCurrentExercise(exercise);
  localStorage.setItem("exercise", exerciseId);

  dbMsg(1, dbl, lm + "Changing current set to next set");
  let setToSet = changeCurrentSet(exercise);
  args.setCurrentSet(setToSet);
  dbMsg(1, dbl, lm + "Finish");
};

export const restartTimer = (timerActions, timeDefault = 90) => {
  if (!timerActions?.restart) {
    dbMsg(1, dbl, lm + "Error: setCurrent functions not passed");
    return false;
  }
  timerActions.restart(timeDefault);
};

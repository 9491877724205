import React, { Fragment, useContext, useEffect, useState } from "react";

import SessionContext from "../../context/session/sessionContext";
import ExerciseContext from "../../context/exercise/exerciseContext";
import AlertContext from "../../context/alert/alertContext";

import SessionHeader from "../sessions/SessionHeader";
import SessionList from "../sessions/SessionList";

import ExerciseItem from "../exercises/ExerciseItem";
import ExerciseDD from "../exercises/ExerciseDD";
import SetItem from "../exercises/SetItem";

import {
  addNewExercise,
  addSet,
  deleteSet,
  deleteTheExercise,
  linkSets,
  reOrder,
  updateSetFields,
} from "../sessions/sessionFunctions";
import {
  addExerciseButton,
  addSessionButton,
  emoticonButton,
  input,
} from "../common/Buttons";
import {
  changeCurrentExercise,
  changeCurrentSet,
  confirmDeleteItem,
  createSetName,
  markCompleted,
  restartTimer,
  sessionUpdate,
  setAllExercisesVisibility,
  setNextExerciseSet,
} from "../sessions/sessionUpdateHandler";
import { debugValues } from "../../utils/functionsCommon";
import Timer from "../common/Timer";
import GIconButton from "../common/GIconButton";
// import Timer from "../common/Timer";

const Sessions = () => {
  const sessionContext = useContext(SessionContext);
  const {
    current,
    getSession,
    setCurrentSession,
    clearCurrentSession,
    addSession,
    updateSession,
    sessions,
    deleteSessions,
    updateSessions,
  } = sessionContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const exerciseContext = useContext(ExerciseContext);
  const { addExercise } = exerciseContext;

  // use to show timer
  const [resetTimer, setResetTimer] = useState(false);
  const [expiryTimestamp, setExpiryTimestamp] = useState(90);

  // const [captureValue, setCaptureValue] = useState("");
  const resetTheTimer = (newExpiryDuration = 90) => {
    setExpiryTimestamp(newExpiryDuration);
    setResetTimer(true);

    setTimeout(function () {
      setResetTimer(false);
    }, 2000);
  };

  const [showExercises, setShowExercises] = useState(true);

  const [showTimer, setShowTimer] = useState(true);
  const showHideTimer = () => {
    setShowTimer(!showTimer);
    resetTheTimer();
  };

  // used to highlight delete button on press and enable the double click
  const [deletePressed, setDeletePressed] = useState({});

  // used for session edit of individual session
  const [session, setSession] = useState(current);
  const [edit, setEdit] = useState(false);
  const [newExercise, setNewExercise] = useState({
    name: "enter exercise name",
    _id: "new",
  });

  // used to show Exercise highlight / spotlight
  const highlightWholeSet = false;
  let exercisesNotCompleted = !session?.exercises?.length
    ? []
    : session?.exercises.filter((exercise) => {
        // !exercise.completed
        if (!exercise?.sets?.length) return false;
        let setsNotDone = exercise.sets.filter((set) => !set.completed);
        return setsNotDone?.length;
      });
  let allExercisesDone =
    !exercisesNotCompleted.length || exercisesNotCompleted.length === 0;
  const [currentExercise, setCurrentExercise] = useState();
  const [currentExerciseId, setCurrentExerciseId] = useState();
  const [currentSet, setCurrentSet] = useState();

  const setTheCurrentExercise = (exerciseToFind) => {
    if (!session?.exercises?.length) return;

    let exerciseIndex = session.exercises.findIndex(
      (exercise) => exercise?._id === exerciseToFind?._id
    );
    if (exerciseIndex > -1) {
      setCurrentExercise(exerciseToFind);
      setCurrentExerciseId(exerciseIndex);
    }
  };

  // used for session edit of session list
  const [sessionsToEdit, setSessionsToEdit] = useState([]);

  // used to mark if we are editing the session list or not
  const [editSessions, setEditSessions] = useState(false);

  useEffect(() => {
    // get active session being viewed
    let activeSession = localStorage.getItem("session");
    if (activeSession) {
      if (!session && !current) {
        getSession(activeSession);
      }
    }

    // set the current sesion from context to session state
    setSession(current);

    // set all the sessions to state in order to edit the list
    setSessionsToEdit(sessions);

    // set the active exercise/set based on last exercise or what's not completed
    let exerciseToSet = changeCurrentExercise(current);
    if (exerciseToSet) {
      setTheCurrentExercise(exerciseToSet);
      // setCurrentExercise(exerciseToSet);

      let setToSet = changeCurrentSet(exerciseToSet);
      setCurrentSet(setToSet);
    }
  }, [current, sessions]);

  // add new exercise to the current workout session
  const addExerciseToUserSession = (newExercise) => {
    addExercise(newExercise);
    let newSesh = addNewExercise(session, newExercise);
    setSession(newSesh);
  };

  const nextLastExercise = (exerciseId) => {
    localStorage.setItem("exercise", exerciseId);
  };

  const updSessionInList = (updSessions, inputId, value) => {
    let ids = inputId.split(".");
    let toChange = ids[0];
    let sessionId = ids?.[1] || -1;

    updSessions[sessionId] = updateSessionItem(
      updSessions[sessionId],
      toChange,
      value
    );
    return updSessions;
  };

  const updateSessionItem = (session, toChange, value) => {
    session[toChange] = value;
    session.changed = true;
    return session;
  };

  // select / unselect session from sessions list
  const selectSessionFromSessions = (sessions, e) => {
    let selected = e.selected;
    let _id = e.id;
    let newSessions = [...sessions];

    newSessions.map((session) => {
      if (_id === session._id) {
        session.selected = !selected;
      }
    });
    return newSessions;
  };

  const deleteSessionsFromList = (sessionsToEdit) => {
    if (!sessionsToEdit?.length) return [];
    return sessionsToEdit.filter((session) => session?.selected);
  };

  const setEditAndSessionsToEdit = () => {
    setEditSessions(!editSessions); // stop editing
    setSessionsToEdit(sessions); // mark sessions to edit
  };

  const updateSessionsHandler = (change, e, inputId) => {
    // multiple session handling

    switch (change) {
      case "edit": {
        setEditAndSessionsToEdit();
        break;
      }
      case "select": {
        let updSessions = selectSessionFromSessions(sessionsToEdit, e);
        setSessionsToEdit(updSessions);
        break;
      }
      case "update": {
        // update items (date or name) in sessions list
        let updSessions = [...sessionsToEdit];
        let value = e?.target?.value;

        updSessions = updSessionInList(updSessions, inputId, value);
        setSessionsToEdit(updateSessions);
        break;
      }
      default:
      case "save": {
        if (sessionsToEdit?.length) updateSessions(sessionsToEdit, true);
        break;
      }
      case "delete": {
        let selectedSessions = deleteSessionsFromList(sessionsToEdit);
        deleteSessions(selectedSessions);
        break;
      }
      case "close":
        return clearTheSessions();
    }
  };

  const updateSessionHandler = (change, e) => {
    // individual session handling
    switch (change) {
      case "clone":
      case "add": {
        addSession(e.target.value);
        break;
      }
      case "open": {
        setActiveSession(e.session);
        break;
      }
      case "edit": {
        setEdit(!edit);
        break;
      }
      default:
      case "save":
        updateSession(session, true);
        break;

      case "close": {
        clearTheSession();
        break;
      }
      case "update": {
        let ctl = e.target.name;
        let ids = ctl.split(".");
        let toChange = ids[0];
        let newSesh = { ...session };
        newSesh = updateSessionItem(newSesh, toChange, e.target.value);
        setSession(newSesh);
        //   let timerActions = { restart: resetTheTimer };
        //   updateSession(newSesh, false);
        break;
      }
    }
  };

  const updateExerciseField = (exercise, field, e) => {
    switch (field) {
      case "name": {
        // update exercise name
        exercise.name = { name: e.target.value, _id: "new" };
        break;
      }
      case "type":
      case "object":
      case "comments":
      case "supersetNo":
      case "default": {
        exercise[field] = e.target.value;
      }
    }
    return exercise;
  };

  const updateExerciseHandler = (change, e, inputId) => {
    let newSesh = { ...session };
    let [_, exIdx, field] = e.target.name.split(".");
    let exercise = newSesh.exercises[exIdx];
    let updateOn = [
      // "reorder",
      "addnew",
      "delete",
      "showhide",
      "showhideall",
      "rate",
      "completeall",
      "completeone",
    ];
    // /    let dontUpdateOn = ["reorder", "reorder", "addnew", "delete", "showhide", "showhideall", "rate", "completeall", "completeone"]

    switch (change) {
      case "reorder": {
        let newExs = reOrder(e, session);
        setSession({ ...session, exercises: newExs });
        updateSession({ ...session, exercises: newExs }, false);
        break;
      }
      case "update": {
        if (e.target.name === "new-exercise-name") {
          let newEx = newExercise?._id
            ? { ...newExercise, name: e.target.value }
            : { _id: "new", name: e.target.value };
          setNewExercise(newEx);
        } else {
          exercise = updateExerciseField(exercise, field, e);
          newSesh.exercises[exIdx] = exercise;
        }
        break;
      }
      case "addnew": {
        // add the new Exercise from text field to the session
        //    this can come from free text or the dropdown or existing
        //    need to add validation to not add twice
        addExerciseToUserSession(newExercise);
        updateSession(newSesh);
        break;
      }

      case "delete": {
        let exerciseName = "exercise." + exIdx;
        let args = { deletePressed, setDeletePressed, setAlert };
        let deleteIt = confirmDeleteItem(args, exerciseName);
        if (deleteIt)
          newSesh.exercises = deleteTheExercise(newSesh.exercises, exIdx);
        updateSession(newSesh);
        break;
      }

      case "showhide": {
        // show or hide exercise based on existing value
        let exercise = newSesh.exercises[exIdx];
        let visible = exercise?.visible ?? true;
        exercise.visible = !visible;
        newSesh.exercises[exIdx] = exercise;
        updateSession(newSesh);
        break;
      }
      case "showhideall": {
        // show or hide all exercises  based on existing value
        let visible = e?.target?.show ?? true;
        setAllExercisesVisibility;
        newSesh.exercises = setAllExercisesVisibility(
          newSesh.exercises,
          !visible
        );
        updateSession(newSesh);
        break;
      }
      case "rate": {
        // update emoticon rating
        exercise.rating = e?.target?.rating > 0 ? e.target.rating : 0;
        newSesh.exercises[exIdx] = exercise;
        updateSession(newSesh);
        break;
      }
      case "completeall": {
        // mark all exercises as completed or not
        newSesh.exercises = newSesh.exercises.map((exercise) => {
          let completed = e?.target?.allCompleted ?? true;
          return markCompleted(exercise, -1, "exercise", completed);
        });

        // go-to the next exercise, even if completed
        //   maybe make only if not completed
        let args = { setCurrentExercise: setTheCurrentExercise, setCurrentSet };
        setNextExerciseSet(args, newSesh, currentExerciseId, currentSet.no);
        updateSession(newSesh);
        break;
      }
      case "completeone": {
        // mark set as completed and set date / time
        // update exersices if all sets completed
        exercise = markCompleted(exercise, -1, "exercise");
        newSesh.exercises[exIdx] = exercise;

        // go-to the next exercise, even if completed
        //   maybe make only if not completed
        let args = { setCurrentExercise: setTheCurrentExercise, setCurrentSet };
        setNextExerciseSet(args, newSesh, currentExerciseId, currentSet.no - 1);
        updateSession(newSesh);

        // reset exercise timer if marked completed
        let timerActions = { restart: resetTheTimer };
        if (exercise?.completed !== false) {
          restartTimer(timerActions, 90);
        }
        break;
      }
      default: {
        break;
      }
    }
    if (updateOn.includes(change)) updateSession(newSesh);
    setSession(newSesh);
  };

  const updateSetsHandler = (change, e, inputId) => {
    let newSesh = { ...session };
    let [_, exIdx, __, setId] = e.target.name.split(".");
    let sets = newSesh.exercises[exIdx].sets;
    let updateOn = ["add", "delete", "link"];

    switch (change) {
      case "add": {
        sets = addSet(sets);
        break;
      }
      case "delete": {
        // let setId = ids?.[3];
        let args = { deletePressed, setDeletePressed, setAlert };
        let deleteIt = confirmDeleteItem(args, ctl);
        if (deleteIt) sets = deleteSet(sets, setId);
        break;
      }
      case "link": {
        sets = linkSets(sets, e.target.link);
        break;
      }
    }
    if (sets?.length > 0) newSesh.exercises[exIdx].sets = sets;
    if (updateOn.includes(change)) updateSession(newSesh);
    setSession(newSesh);
  };

  const updateSetHandler = (change, e, inputId) => {
    let newSesh = { ...session };
    let [_, exIdx, __, setId, field] = e.target.name.split(".");
    let exercise = newSesh.exercises[exIdx];
    let sets = exercise.sets;
    let set = sets[setId];
    let value = e.target.value;

    if (set && set?.highlight) delete set.highlight;
    let updateOn = ["add", "link", "start", "finish", "completeone"];

    switch (change) {
      case "add": {
        sets = addSet(sets);
        break;
      }
      case "link": {
        sets[setId].link = set?.link ? !set.link : true;
        break;
      }
      case "start": {
        sets[setId].started = true;
        sets[setId].startedWhen = new Date();
        if (setId === 0 || !exercise?.started) {
          exercise = {
            ...exercise,
            started: true,
            startedWhen: new Date(),
            sets: sets,
          };
          newSesh.exercises[exIdx] = exercise;
        }
        break;
      }
      case "finish":
      case "completeone": {
        // mark set as completed and set date / time
        // update exersices if all sets completed
        newSesh.exercises[exIdx] = markCompleted(exercise, setId, "set");

        // go-to the next exercise, even if completed
        //   maybe make only if not completed
        let args = { setCurrentExercise: setTheCurrentExercise, setCurrentSet };
        setNextExerciseSet(
          args,
          newSesh,
          currentExerciseId,
          currentSet?.no ?? -1
        );

        // reset exercise timer if marked completed
        let timerActions = { restart: resetTheTimer };
        restartTimer(timerActions, 90);
        break;
      }
      case "default":
      case "update": {
        sets = updateSetFields(sets, setId, field, value);
        break;
      }
    }
    if (sets?.length > 0) newSesh.exercises[exIdx].sets = sets;
    if (updateOn.includes(change)) updateSession(newSesh);
    setSession(newSesh);
  };

  const buttonAction = (args, inputId) => {
    const action = args.action;
    const e = args.e;

    // console.log("-----------------");
    // debugValues({ args, inputId, session, action });
    // return;

    const sectionToUpdate = action.split("-");
    if (
      ["session", "sessions", "exercises", "exercise", "sets", "set"].includes(
        sectionToUpdate[1]
      )
    ) {
      switch (sectionToUpdate[1]) {
        case "session":
          updateSessionHandler(sectionToUpdate[0], e, inputId);
          break;
        case "sessions":
          updateSessionsHandler(sectionToUpdate[0], e, inputId);
          break;
        case "exercise":
          updateExerciseHandler(sectionToUpdate[0], e, inputId);
          break;
        case "sets":
          updateSetsHandler(sectionToUpdate[0], e, inputId);
          break;
        case "set":
          updateSetHandler(sectionToUpdate[0], e, inputId);
          break;
        default:
          console.log("no section specified");
      }
    }
  };

  // on selecting new session, update it in localstorage
  //    and set session as the current in context
  const setActiveSession = (session) => {
    localStorage.setItem("session", session._id);
    setCurrentSession(session);
  };

  // New exericse drop down for current session
  const addExerciseForm = () => (
    <Fragment>
      <div className="row p-2" key={"add-exercise-r1"}>
        <div className="col-12" key={"add-exercise-r1c1"}>
          <ExerciseDD setNewExercise={setNewExercise} />
          {input(
            newExercise.name,
            "new-exercise-name",
            "text",
            buttonAction,
            true,
            "exercise"
          )}
          {addExerciseButton(buttonAction)}
        </div>
      </div>
    </Fragment>
  );

  // used for session edit of session list
  const clearTheSessions = () => {
    setEditSessions(null);
    setSessionsToEdit([]);
  };

  // used for session edit of individual session
  const clearTheSession = () => {
    setSession(null);
    localStorage.removeItem("session");
    clearCurrentSession();
  };

  // show exercise and current set in exercise highlight section
  const showCurrentExercise = (exercise, set) => {
    let setId;
    // TO-DO: exercise.order seems missing.  not sure why
    if (set) setId = createSetName(currentExerciseId, set.no - 1);

    return (
      <Fragment>
        <div className="row bg-warning">
          <div className="col py-1">
            <h4 className="  text-center">
              <strong>{exercise?.name?.name ?? ""}</strong>
            </h4>
          </div>
        </div>
        <div className="row mx-1">
          <div className="col">
            {!set ? (
              ""
            ) : (
              <SetItem
                set={{ ...set, duplicateField: 1 }}
                updateFunction={buttonAction}
                setId={setId}
                idx={parseInt(set.no) - 1}
                key={setId}
                edit={edit}
                deletePressed={deletePressed}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  // show highlighted session
  const showHighlightAndTime = (showTimer) => {
    if (!showTimer) return "";
    let finished = !session?.exercises?.length || allExercisesDone;
    let classes = finished ? "success" : "warning";

    const showExerciseOrDone = () => {
      if (finished)
        return (
          <Fragment>
            <div className={`row bg-${classes}`}>
              <div className="col py-1">
                <h4 className="text-white text-center">All Exercises Done</h4>
              </div>
            </div>
            <div className="row mx-1">
              <div className="col text-center p-3">
                <span
                  className="material-symbols-outlined text-success"
                  style={{ fontSize: "5em" }}
                >
                  {"task_alt"}
                </span>
              </div>
            </div>
          </Fragment>
        );

      let exerciseSetId = createSetName(currentExerciseId, currentSet.no - 1);
      return (
        <Fragment>
          {showCurrentExercise(currentExercise, currentSet)}
          <Timer
            resetTimer={resetTimer}
            expiryTimestamp={expiryTimestamp}
            showTimer={showTimer}
            parentButtonAction={buttonAction}
            exerciseSetId={exerciseSetId}
          />
        </Fragment>
      );
    };
    return (
      <div className={`container border border-2 border-${classes} p-0 m-0`}>
        <div className="row">
          <div className="col-12 m-0 p-0 pb-1">{showExerciseOrDone()}</div>
        </div>
      </div>
    );
  };

  if (!session)
    return (
      <SessionList
        sessionsSelected={sessions}
        buttonFunction={buttonAction}
        edit={editSessions}
        sessionsToEdit={sessionsToEdit}
        setSessionsToEdit={setSessionsToEdit}
      />
    );

  // let showHighlightTimer = !session?.exercises?.length || allExercisesDone || !showTimer
  let exercisesLength = session?.exercises?.length || 0;
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 m-0 p-0">
            <div className="d-flex flex-column vh-100 overflow-hidden">
              <div className="row">
                <div className="col-12">
                  <SessionHeader
                    session={session}
                    buttonFunction={buttonAction}
                    edit={edit}
                    buttonText="back"
                    idx={0}
                    exerciseLength={exercisesLength}
                    key={"session-header"}
                    what={"session"}
                    showTimer={showTimer}
                    showHideTimer={showHideTimer}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">{showHighlightAndTime(showTimer)}</div>
              </div>
              <div className="row pt-4 border border-1">
                <div className="col-8">
                  <span className="h4">Exercises</span>
                </div>
                <div className="col-4 text-end">
                  <GIconButton
                    func={setShowExercises}
                    arg={!showExercises}
                    classes={"h2 p-0 m-0"}
                    iconSize={"2em"}
                    // style={{ "font-size": "2em" }}
                    k={"btnShowHideExerciseList"}
                    icon={showExercises ? "arrow_drop_up" : "arrow_drop_down"}
                  />
                </div>
              </div>

              <div className="h-100 scrollable overflow-auto">
                {!showExercises
                  ? ""
                  : session?.exercises?.length
                  ? session.exercises.map((exercise, idx) => (
                      <ExerciseItem
                        exercise={exercise}
                        ctlId={"exercise." + idx}
                        idx={idx}
                        updateFunction={buttonAction}
                        orderFunction={buttonAction}
                        key={"exercise." + idx}
                        edit={edit}
                        exerciseCount={session?.exercises?.length ?? 0}
                        deletePressed={deletePressed}
                      />
                    ))
                  : "Add Exercise"}
                {addExerciseForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sessions;

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTimer } from "react-timer-hook";
import {
  getExpiryTime,
  getNowTime,
  padTimes,
} from "../../utils/functionsCommon";

const Timer = ({
  resetTimer,
  expiryTimestamp = 90,
  showTimer,
  parentButtonAction,
  exerciseSetId,
}) => {
  if (!showTimer) return "";

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      localStorage.removeItem("expiryTimestamp");
      // console.warn("onExpire called");
    },
  });

  const restartTimer = (expiryDuration) => {
    let expiryTimestamp = getExpiryTime(expiryDuration);
    localStorage.setItem("expiryTimestamp", expiryTimestamp);
    restart(expiryTimestamp);
  };

  const restartFromStorage = () => {
    let expiryTimeText = localStorage.getItem("expiryTimestamp");
    let expiryTimestamp = parseInt(expiryTimeText);
    let nowTimestamp = getNowTime();

    if (expiryTimestamp > nowTimestamp) {
      restart(expiryTimestamp);
    }
  };

  useEffect(() => {
    if (resetTimer) {
      restartTimer(expiryTimestamp);
      resetTimer = false;
    } else if (localStorage.getItem("expiryTimestamp")) {
      restartFromStorage();
    }
  }, [resetTimer, expiryTimestamp]);

  return (
    <Fragment>
      <div className="container">
        <div className="row m-0 p-0 align-items-center">
          <div className="col text-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                restartTimer(expiryTimestamp);
                let startArg = {
                  action: "start-set",
                  e: { target: { name: exerciseSetId, value: "start" } },
                  // e: { target: { name: "current-set", value: "start" } },
                };
                parentButtonAction(startArg);
              }}
            >
              Start
            </button>
          </div>
          <div className="col-6 text-center">
            <span style={{ fontSize: "2em" }}>
              {padTimes(minutes)}:{padTimes(seconds)}
            </span>
          </div>
          <div className="col text-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                stop();
                let completedArg = {
                  action: "finish-set",
                  e: { target: { name: exerciseSetId, value: "finish" } },
                  // e: { target: { name: "current-set", value: "finish" } },
                };
                parentButtonAction(completedArg);
              }}
            >
              Finish
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row m-0 p-0 bg-light">
          <div className="col-12 text-center">
            <button className="btn btn-sm btn-primary" onClick={pause}>
              Pause
            </button>
            <button className="btn btn-sm btn-primary" onClick={resume}>
              Resume
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                restartTimer(expiryTimestamp);
              }}
            >
              Restart
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Timer.propTypes = {
  resetTimer: PropTypes.bool,
  expiryTimestamp: PropTypes.number,
  showTimer: PropTypes.bool,
  parentButtonAction: PropTypes.func,
  exerciseSetId: PropTypes.string,
};

export default Timer;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SetItem from "../exercises/SetItem";
import {
  input,
  showHideButton,
  exerciseItemButtons,
  showOrHideButton,
} from "../common/Buttons";

const ExerciseItem = ({
  exercise,
  updateFunction,
  edit,
  ctlId,
  idx,
  exerciseCount,
  deletePressed,
  showButtons = true,
}) => {
  let k = ctlId;
  let showTheExercise =
    exercise?.visible === undefined ? true : exercise.visible;
  let completed = exercise?.completed === undefined ? true : exercise.completed;
  let exerciseName = exercise?.name?.name ?? "Exercise no. " + idx;

  return (
    <Fragment>
      <div
        className={`row p-2 border-bottom text-start ${
          completed ? "bg-success" : "bg-primary"
        }`}
        key={k + ".r1"}
      >
        <div className="col-6 my-auto text-start" key={k + ".r1c1"}>
          {!showButtons ? (
            <span className=" h4 text-white">{exerciseName}</span>
          ) : (
            showOrHideButton(
              { func: updateFunction },
              ctlId,
              exercise?.visible,
              exerciseName
            )
          )}
        </div>
        {!showButtons ? (
          ""
        ) : (
          <div className="col-6 my-auto text-end" key={k + ".r1c2"}>
            {exerciseItemButtons(
              updateFunction,
              ctlId,
              idx,
              exerciseCount || 0,
              exercise,
              deletePressed
            )}
          </div>
        )}
      </div>
      <div
        className={`container m-0 p-0 collapse collapseAll ${
          showTheExercise ? "show" : ""
        }`}
        id={`collapse-${k.replace(".", "-")}`}
        key={k + ".exercises"}
      >
        <div className={`row p-2`} key={k + ".r2 "}>
          <div className="col-12" key={k + ".r2c1"}>
            {exercise?.sets?.length
              ? exercise.sets.map((set, sIdx) => {
                  let setId = ctlId + ".set." + sIdx;
                  return (
                    <SetItem
                      set={set}
                      updateFunction={updateFunction}
                      idx={sIdx}
                      setId={setId}
                      key={setId}
                      edit={edit}
                      deletePressed={deletePressed}
                    />
                  );
                })
              : "Add Set"}
          </div>
        </div>
        <div className={`row p-2 border-bottom`} key={k + ".r3"}>
          <div className="col-3" key={k + ".r3c1"}>
            {input(
              exercise?.object || "",
              // exercise?.object || "cable",
              ctlId + ".object",
              "text",
              updateFunction,
              edit,
              "exercise"
            )}
            {/* {input(exercise?.object || "cable", "object", "text")} */}
          </div>
          <div className="col-6" key={k + ".r3c2"}>
            {/* {input(
              exercise?.type || "",
              // exercise?.type || "normal set",
              ctlId + ".type",
              "text",
              updateFunction,
              edit,
              "exercise"
            )} */}
            {input(
              exercise?.type || "",
              ctlId + ".type",
              "select",
              updateFunction,
              edit,
              "exercise",
              ["normal", "superset"]
            )}
          </div>
          <div className="col-3" key={k + ".r3c3"}>
            {exercise?.type !== "superset"
              ? ""
              : input(
                  exercise?.supersetNo || "",
                  ctlId + ".supersetNo",
                  "text",
                  updateFunction,
                  edit,
                  "exercise"
                )}
          </div>
        </div>
        <div className={`row p-2 border-bottom`} key={k + ".r4"}>
          <div className="col-12" key={k + ".r4c1"}>
            {input(
              exercise?.comments || "",
              // exercise?.comments || "comments",
              ctlId + ".comments",
              "text",
              updateFunction,
              edit,
              "exercise"
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ExerciseItem.propTypes = {
  exercise: PropTypes.object.isRequired,
  updateFunction: PropTypes.func.isRequired,
  ctlId: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  exerciseCount: PropTypes.number,
  edit: PropTypes.bool.isRequired,
  deletePressed: PropTypes.object,
  showButtons: PropTypes.bool,
};
export default ExerciseItem;

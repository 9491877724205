import React, { useContext, useEffect } from "react";
import SessionContext from "../../context/session/sessionContext";
import Sessions from "../sessions/Sessions";

const SessionHome = () => {
  const sessionContext = useContext(SessionContext);
  const { getSessions, getSessionCount } = sessionContext;

  useEffect(() => {
    getSessions();
    getSessionCount();

    // eslint-disable-next-line
  }, []);

  return (
    // <div className="container p-0 m-0">
    <Sessions />
    // </div>
  );
};

export default SessionHome;

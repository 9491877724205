import React from "react";
import PropTypes from "prop-types";

const GIconButton = ({
  func,
  icon,
  arg,
  k,
  classes,
  disabled,
  text,
  style = {},
  iconSize = "1.4em",
}) => {
  return (
    <button
      type="button"
      className={"btn btn-sm " + classes}
      key={k}
      onClick={() => func(arg)}
      data-bs-toggle="button"
      disabled={disabled}
      {...style}
    >
      {text ? (
        <span className={`text-start h4 text-white`}>{text}</span>
      ) : (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: iconSize }}
        >
          {icon}
        </span>
      )}
    </button>
  );
};

GIconButton.propTypes = {
  func: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  k: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
  iconSize: PropTypes.string,
  arg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default GIconButton;

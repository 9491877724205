import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import SessionContext from "../../context/session/sessionContext";
import SessionHeader from "../sessions/SessionHeader";

import Pagingation from "../common/Pagingation";

import {
  addSessionButton,
  deleteButton,
  editCancelButton,
  saveButton,
  saveSessionsButton,
} from "../common/Buttons";

const SessionList = ({ buttonFunction, sessionsSelected, edit }) => {
  const sessionContext = useContext(SessionContext);
  const { sessions, sessionCount } = sessionContext;

  const [sessionsToEdit, setSessionsToEdit] = useState([]);
  useEffect(() => {
    setSessionsToEdit(sessions);
  }, [sessions, sessionsToEdit, sessionCount]);

  return (
    <Fragment>
      <div className="row p-2" key={"add-session-r1"}>
        <div className="col-6 my-auto" key={"add-session-r1c1"}>
          <div className="h4">Workout Sessions</div>
        </div>
        <div className="col-6 text-end  my-auto" key={"add-session-r2c1"}>
          {addSessionButton(buttonFunction)}
          {edit ? saveSessionsButton(buttonFunction, sessionsToEdit) : ""}
          {edit ? deleteButton(buttonFunction) : ""}
          {editCancelButton(buttonFunction, "sessions", edit)}
        </div>
      </div>
      {sessionsToEdit?.length ? (
        sessionsToEdit.map((session, idx) => {
          let k = "session-" + idx;
          let selected = session?.selected ?? false;
          return (
            <Fragment key={k + "-frag"}>
              <SessionHeader
                session={session}
                edit={edit}
                selected={selected}
                buttonFunction={buttonFunction}
                buttonText="forward"
                idx={idx}
                key={"session-header"}
                exerciseLength={session?.exercises?.length || 0}
                what={"sessions"}
              />
              <div className="row border-bottom" key={k + `-r2`}>
                <div className="col-12 " key={k + `-c-button`}></div>
              </div>
            </Fragment>
          );
        })
      ) : (
        <p>no sessions</p>
      )}
      <Pagingation sessionCount={sessionCount} />
    </Fragment>
  );
};

SessionList.propTypes = {
  buttonFunction: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  sessionsSelected: PropTypes.array,
  sessionsToEdit: PropTypes.array,
  setSessionsToEdit: PropTypes.func,
};

export default SessionList;

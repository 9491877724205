import React from "react";
import PropTypes from "prop-types";

const Emoji = (props) => (
  <span
    className="emoji p-0 m-0"
    style={props?.style ? props.style : { fontSize: "1.5em" }}
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);
Emoji.propTypes = {
  symbol: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
};
export default Emoji;

import React, { Fragment } from "react";
import GIconButton from "../common/GIconButton";
import ShowHideButton from "../common/ShowHideButton";
import InputField from "../common/InputField";
import Emoji from "./Emoji";

export const exerciseItemButtons = (
  updateFunction,
  ctlId,
  idx,
  size,
  exercise,
  deletePressed
) => {
  let buttonDivClass = "b-0 m-0 p-0";
  let defOpts = {
    func: updateFunction,
    // classes: deleteIsYellow ? "text-warning font-weight-bold " : "text-white",
    classes: `text-white`,
    deletePressed: deletePressed,
  };
  let deleteIsYellow = deletePressed?.[ctlId] ? true : false;
  let deleteOpts = {
    ...defOpts,
    classes: deleteIsYellow ? "text-warning font-weight-bold " : "text-white",
  };
  let deleteClasses = deleteIsYellow ? "text-danger font-weight-bold " : "";
  // let deleteClasses = deleteIsYellow ? "text-warning font-weight-bold " : "text-white"
  let exerciseCompleted = exercise?.completed ?? false;

  return (
    <Fragment>
      <div className={`row ${buttonDivClass}`}>
        <div className={`col ${buttonDivClass}`}>
          {completedButton(defOpts, ctlId, exerciseCompleted, "exercise")}
          {linkButton(defOpts, ctlId, true)}
          {reorderButton(defOpts, ctlId, true, size, idx)}
          {showOrHideButton(defOpts, ctlId, exercise?.visible, "")}
        </div>
      </div>
      <div className={`row ${buttonDivClass}`}>
        <div className={`col ${buttonDivClass}`}>
          {/* {addSetButton(defOpts, ctlId)} */}
          {/* {deleteTheExercise(deleteOpts, ctlId)} */}
          {emoticonButton(defOpts, ctlId, exercise?.rating ?? 0, "exercise")}
          {linkButton(defOpts, ctlId, false)}
          {reorderButton(defOpts, ctlId, false, size, idx)}
          {exerciseDropDown(
            { ...defOpts, classes: "" },
            { ...defOpts, classes: deleteClasses },
            ctlId
          )}
        </div>
      </div>
    </Fragment>
  );
};
// const GIconButton = ({ func, icon, arg, k, classes, disabled, text }) => {
export const showOrHideButton = (defaultOpts, ctlId, show, text) => {
  let k = ctlId;
  let showHideAll = ctlId === "exercise.all" ? "showhideall" : "showhide";
  defaultOpts = text ? { ...defaultOpts, text } : defaultOpts;
  return (
    <GIconButton
      {...defaultOpts}
      icon={!show ? "visibility" : "visibility_off"}
      arg={{
        action: showHideAll + "-exercise",
        // action: "update-exercise",
        e: getE(ctlId, showHideAll, { show }),
      }}
      k={k + `-btn-${show ? "show" : "hide"}-${showHideAll}-exercise`}
    />
  );
};

export const showHideButton = (
  k,
  icon,
  isText,
  addClass,
  showExercise,
  showHideExercise,
  exerciseId
) => (
  <ShowHideButton
    toggle={"collapse"}
    target={`#collapse-${k.replace(".", "-")}`}
    icon={icon}
    isText={isText}
    controls={"collapse"}
    k={k}
    classes={"text-white " + addClass}
    showExercise={showExercise}
    showHideExercise={showHideExercise}
    exerciseId={exerciseId}
  />
);

export const addSessionButton = (buttonFunction) => (
  <GIconButton
    func={buttonFunction}
    arg={{
      action: "add-session",
      e: { target: { name: "session", value: "new" } },
    }}
    k={"btnAddSession"}
    icon={"add_circle"}
  />
);

export const cloneButton = (buttonFunction, session) => (
  <GIconButton
    func={buttonFunction}
    icon={"file_copy"}
    arg={{
      action: "clone-session",
      e: { target: { name: "clone-session", value: session._id } },
    }}
    k={"btnClone"}
  />
);

export const openCloseButton = (buttonFunction, session, buttonText) => (
  <GIconButton
    func={buttonFunction}
    icon={"arrow_" + buttonText}
    arg={{
      action: buttonText === "forward" ? "open-session" : "close-session",
      e: { session: session },
    }}
    k={"btnOpenClose"}
  />
);

export const editSaveButtons = (buttonFunction, session, edit) => {
  if (!edit && buttonFunction)
    return editCancelButton(buttonFunction, "session", edit);
  if (edit && buttonFunction)
    return (
      <Fragment>
        {saveButton(buttonFunction, session)}{" "}
        {editCancelButton(buttonFunction, "session", edit)}
      </Fragment>
    );
  return "";
};

export const saveButton = (buttonFunction, session) => (
  <GIconButton
    func={buttonFunction}
    icon={"save"}
    arg={{ action: "save-session", session: session }}
    k={"btnSave"}
  />
);

export const saveSessionsButton = (buttonFunction, sessions) => (
  <GIconButton
    func={buttonFunction}
    icon={"save"}
    arg={{ action: "save-sessions", sessions: sessions }}
    k={"btnSaveSessions"}
  />
);

export const editCancelButton = (buttonFunction, what, edit) => {
  let action = "edit" + (what ? "-" + what : "");
  return (
    <GIconButton
      func={buttonFunction}
      icon={!edit ? "edit" : "close"}
      arg={{ action }}
      k={"btnEdit"}
    />
  );
};

export const cancelButton = (buttonFunction) => (
  <GIconButton
    func={buttonFunction}
    icon={"close"}
    arg={{ action: "edit" }}
    k={"btnEdit"}
  />
);

export const deleteButton = (buttonFunction) => (
  <GIconButton
    func={buttonFunction}
    icon={"delete"}
    arg={{ action: "delete-sessions" }}
    k={"btnDelete"}
  />
);

export const deleteSetButton = (
  updateFunction,
  k,
  setId,
  deletePressed,
  completed = false
) => {
  let className = completed ? "text-white" : "";
  if (deletePressed?.[setId]) {
    className = "text-danger font-weight-bold ";
  }

  return (
    <GIconButton
      classes={className}
      func={updateFunction}
      icon={"delete"}
      arg={{
        action: "delete-sets",
        e: { target: { name: setId, value: "delete-sets" } },
      }}
      k={k + `-btn-add-sets`}
    />
  );
};

export const linkSetButton = (
  updateFunction,
  k,
  setId,
  link,
  completed = false
) => (
  <GIconButton
    classes={completed ? "text-white" : ""}
    func={updateFunction}
    // icon={set?.link ? "link" : "link_off"}
    icon={link ? "link" : "link_off"}
    // icon={set?.link ? "link" : "link_off"}
    arg={{
      action: "link-set",
      e: {
        target: {
          name: setId,
          value: "link-set",
          link: !(link ?? true),
          // link: !(set?.link ?? true),
        },
      },
    }}
    k={k + `-btn-link-set`}
  />
);

export const showHideAll = (updateFunction) => {
  let defaultOpts = {
    func: updateFunction,
  };

  return (
    <Fragment>
      {showOrHideButton(defaultOpts, "exercise.all", false)}
      {showOrHideButton(defaultOpts, "exercise.all", true)}
    </Fragment>
  );
};

export const input = (
  value,
  name,
  type,
  buttonFunction,
  edit,
  what,
  options
) => {
  if (!edit) return value;
  return (
    <InputField
      name={name}
      inputId={name}
      value={value}
      handlerFunc={buttonFunction}
      type={type}
      key={name}
      what={what}
      options={options}
    />
  );
};

export const selectSessionBox = (id, selected, buttonFunction) => {
  let args = {
    e: { id: id, selected: selected },
    action: "select-sessions",
    // action: "select-sessions-list-item",
  };
  return (
    <input
      className="form-check-input  align-middle"
      type="checkbox"
      id="inlineCheckbox1"
      value="option1"
      onChange={() => buttonFunction(args)}
      checked={selected}
    />
  );
};

const getE = (ctlId, value, other) => {
  return { target: { name: ctlId, value: value, ...other } };
};

export const linkButton = (defaultOpts, ctlId, link, completed = true) => {
  let k = ctlId;
  return (
    <GIconButton
      {...defaultOpts}
      classes={completed ? "text-white" : ""}
      icon={link ? "link" : "link_off"}
      arg={{
        action: "link-sets",
        e: getE(ctlId, "link-sets", { link }),
      }}
      k={k + `-btn-${link ? "" : "un"}linkall-set`}
    />
  );
};
// export const linkButton = (defaultOpts, ctlId, link, completed = true) => {
//   let k = ctlId;
//   return (
//     <GIconButton
//       {...defaultOpts}
//       classes={completed ? "text-white" : ""}
//       icon={link ? "link" : "link_off"}
//       arg={{
//         action: "update-exercise",
//         e: getE(ctlId, "link-all", { link }),
//       }}
//       k={k + `-btn-${link ? "" : "un"}linkall-set`}
//     />
//   );
// };

export const showTimerButton = (defaultOpts, ctlId, showTimer, what) => (
  <GIconButton
    {...defaultOpts}
    icon={"fitness_center"}
    arg={{
      action: "update-" + what,
      e: getE(ctlId, "showTimer", { showTimer }),
    }}
    k={ctlId + `-btn-timer-${showTimer ? "show" : "hide"}-${what}`}
  />
);

export const startedButton = (defaultOpts, ctlId, k, started, what) => (
  <GIconButton
    classes={started ? "text-white" : ""}
    {...defaultOpts}
    icon={started ? "play_circle" : "stop_circle"}
    arg={{
      action: "update-" + what,
      e: getE(ctlId, "started", { completed }),
    }}
    k={k + `-btn-${started ? "started" : "pending"}-${what}`}
  />
);

export const completedButton = (defaultOpts, ctlId, completed, what) => {
  let k = ctlId;
  return (
    <GIconButton
      classes={completed ? "text-white" : ""}
      {...defaultOpts}
      icon={completed ? "check_circle" : "pending"}
      // arg={{
      //   action: "update-" + what,
      //   e: getE(ctlId, "completed", { completed }),
      // }}
      arg={{
        action: "completeone-" + what,
        e: getE(ctlId, "completed", { completed }),
      }}
      k={k + `-btn-${completed ? "completed" : "pending"}-${what}`}
    />
  );
};

export const completeAllButton = (
  defaultOpts,
  ctlId,
  k,
  allCompleted,
  what
) => (
  <GIconButton
    {...defaultOpts}
    icon={allCompleted ? "check_circle" : "pending"}
    arg={{
      action: "completeall-" + what,
      e: getE(ctlId, "complete-all", { allCompleted }),
    }}
    // arg={{
    //   action: "update-" + what,
    //   e: getE(ctlId, "complete-all", { allCompleted }),
    // }}
    k={k + `-btn-${allCompleted ? "completeAll" : "pendingAll"}-${what}`}
  />
);

export const reorderButton = (defaultOpts, ctlId, up, size, idx) => {
  let k = ctlId;
  let disabled;
  if (up) {
    disabled = size === 0 || idx === 0;
  } else {
    disabled = size === 0 || idx === size - 1;
  }

  return (
    <GIconButton
      {...defaultOpts}
      icon={"arrow_" + (up ? "upward" : "downward")}
      arg={{
        action: "reorder-exercise",
        e: getE(ctlId, up ? -1 : 1),
      }}
      k={k + `-btn-moveUp`}
      disabled={disabled} // to be fixed, bottom down arrow still shows, should be greyed out as it's the final row
    />
  );
};

// export const emoticonButton = (defaultOpts, ctlId, k) => {
export const exerciseDropDown = (defOpts, deleteOpts, ctlId) => {
  let key = ctlId + "-dropdown-add-del";

  const dropDownButton = (key) => {
    return (
      <button
        className="btn btn-sm text-white"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        key={key + "-button"}
      >
        <span
          className="material-symbols-outlined text-white"
          style={{ fontSize: "1.4em" }}
        >
          {"menu"}
        </span>
      </button>
    );
  };

  return (
    <span key={key + "-dropdown"}>
      {dropDownButton(key)}
      <div className="dropdown-menu" key={key + "-menu"}>
        {/* <div className="container p-0 m-0" key={key + "-container"}> */}
        {/* <div className="row m-1" key={key + "-menu-row"}> */}
        {/* <div className="col m-0 p-0" key={key + `-menu-col-button-add`}> */}
        {addSetButton(defOpts, ctlId)}
        {/* </div> */}
        {/* <div className="col m-0 p-0" key={key + `-menu-col-button-del`}> */}
        {deleteTheExercise(deleteOpts, ctlId)}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </span>
  );
};

export const emoticonButton = (defaultOpts, ctlId, rating, what) => {
  let key = ctlId + "-emoticon-";

  let icons = [
    { label: "woozy", icon: "🥴", value: 1 },
    // { label: "tired", icon: "😩", value: 2 },
    { label: "weary", icon: "😩", value: 2 },
    { label: "ok", icon: "😑", value: 3 },
    { label: "grinning", icon: "😀", value: 4 },
    { label: "flexed", icon: "💪", value: 5 },
  ];

  const getIcon = (rating, icons) => {
    if (rating > 0) {
      let mainIcon = icons[rating - 1];
      return (
        <Emoji
          style={{ fontSize: "1.4em" }}
          symbol={mainIcon.icon}
          label={mainIcon.label}
        />
      );
    }

    return (
      <span
        className="material-symbols-outlined text-white"
        style={{ fontSize: "1.4em" }}
      >
        {"thumb_up"}
      </span>
    );
  };

  const dropDownButton = (rating, icons, key) => {
    return (
      <button
        className="btn btn-sm text-white"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        key={key + "-button"}
      >
        {getIcon(rating, icons)}
      </button>
    );
  };

  return (
    <span key={key + "-dropdown"}>
      {dropDownButton(rating, icons, key)}
      <div className="dropdown-menu" key={key + "-menu"}>
        <div className="container p-0 m-0" key={key + "-container"}>
          <div className="row m-1" key={key + "-menu-row"}>
            {icons.map((iconSet, idx) => {
              const { icon, label, value } = iconSet;
              let action = {
                // action: "update-" + what,
                action: "rate-" + what,
                // e: getE(ctlId, "rating", { rating: value }),
                e: getE(ctlId, "rate", { rating: value }),
              };
              action.e.target.rating = rating === value ? 0 : value;
              return (
                <div className="col m-0 p-0" key={key + `-menu-col-${idx}`}>
                  <button
                    className="border-0 bg-white m-0 p-0"
                    type="button"
                    data-bs-toggle="button"
                    key={key + `-select-button-${idx}`}
                    onClick={() => defaultOpts.func(action)}
                  >
                    <Emoji
                      symbol={icon}
                      label={label}
                      key={key + `-emoji-icon-${idx}`}
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </span>
  );
};

export const addSetButton = (defaultOpts, ctlId) => (
  <GIconButton
    {...defaultOpts}
    arg={{
      action: "add-sets",
      e: getE(ctlId, "add-sets", { link: true }),
    }}
    icon={"add_circle"}
    k={ctlId + `-c-btn-add-sets`}
  />
);

export const deleteTheExercise = (defaultOpts, ctlId) => {
  let k = ctlId;
  return (
    <GIconButton
      {...defaultOpts}
      arg={{
        // action: "update-exercise",
        action: "delete-exercise",
        e: getE(ctlId, "delete-exercise", { link: true }),
      }}
      icon={"delete"}
      k={k + `-c-btn-delete-exercise`}
    />
  );
};

export const addExerciseButton = (buttonFunction, exerciseName) => (
  <GIconButton
    func={buttonFunction}
    arg={{
      action: "addnew-exercise",
      e: { target: { name: "exercise", value: exerciseName } },
    }}
    k={"btnAddExercise"}
    icon={"add_circle"}
  />
);

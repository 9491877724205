import React, { Fragment, StrictMode } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Alerts from "./components/layout/Alerts";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/routing/PrivateRoute";
import Workouts from "./components/pages/Workouts";
import Validate from "./components/auth/Validate";

import AlertState from "./context/alert/AlertState";
import AuthState from "./context/auth/AuthState";
import WorkoutState from "./context/workout/WorkoutState";
import SessionState from "./context/session/SessionState";
import ExerciseState from "./context/exercise/ExerciseState";

import setAuthToken from "./utils/setAuthToken";
import SessionAlerts from "./components/sessions/SessionAlerts";

import "./App.css";
import "./table.css";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  return (
    <StrictMode>
      <AuthState>
        <WorkoutState>
          <SessionState>
            <ExerciseState>
              <AlertState>
                <Router>
                  <Fragment>
                    <Navbar
                      title="Gym Workouts"
                      icon="fas fa-dumbbell white-text"
                    />
                    <div className="container py-0 my-0">
                      {/* <div className="container py-1 my-1"> */}
                      <Alerts />
                      <SessionAlerts />
                      <Switch>
                        <PrivateRoute exact path="/" component={Workouts} />
                        <PrivateRoute
                          exact
                          path="/workouts"
                          component={Workouts}
                        />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/validate" component={Validate} />
                      </Switch>
                    </div>
                  </Fragment>
                </Router>
              </AlertState>
            </ExerciseState>
          </SessionState>
        </WorkoutState>
      </AuthState>
    </StrictMode>
  );
};

export default App;

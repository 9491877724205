import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import {
  cloneButton,
  completeAllButton,
  completedButton,
  editSaveButtons,
  input,
  openCloseButton,
  selectSessionBox,
  showHideAll,
  showTimerButton,
} from "../common/Buttons";
import { agoDateString, fromISOToFmt } from "../../utils/functionsCommon";

const SessionHeader = ({
  session,
  buttonFunction,
  edit,
  selected,
  buttonText,
  exerciseLength,
  idx,
  what,
  showTimer,
  showHideTimer,
}) => {
  let k = "session." + idx;

  let isSessionList = buttonText === "forward";
  let sessionDate;
  if (session?.date) {
    let dateTime = DateTime.fromISO(session?.date);
    if (dateTime.isValid) {
      let agoString = agoDateString(dateTime);
      sessionDate = edit
        ? fromISOToFmt(session?.date, "yyyy-MM-dd")
        : isSessionList
        ? agoString
        : fromISOToFmt(session?.date, "dd MMM yy");
    }
  }

  const completedExercises = !session?.exercises?.length
    ? []
    : session.exercises.filter((ex) => ex?.completed);
  const allCompleted = !session?.exercises?.length
    ? false
    : session.exercises.length === completedExercises.length;

  const sessionButtons = () => (
    <Fragment>
      {!edit || !isSessionList
        ? ""
        : selectSessionBox(session._id, selected, buttonFunction)}
      {!isSessionList ? "" : cloneButton(buttonFunction, session)}
    </Fragment>
  );

  return (
    <Fragment>
      <div className="row py-2 m-0" key={k + `-r1`}>
        <div className="col-3 px-1 my-auto" key={k + `-c-date`}>
          {input(
            sessionDate,
            "date." + idx,
            "date",
            buttonFunction,
            edit,
            what
          )}
        </div>
        <div className="col-6 px-1 text-start my-auto" key={k + `-c-name`}>
          <span className="h6">
            {input(
              session?.name || "",
              "name." + idx,
              "text",
              buttonFunction,
              edit,
              what
            )}{" "}
            {edit ? "" : `(${exerciseLength ?? 0})`}
          </span>
        </div>

        <div
          className="col-3 px-1 text-end align-middle my-auto"
          key={k + `-c-butt`}
        >
          {sessionButtons()}
          {openCloseButton(buttonFunction, session, buttonText)}
        </div>
      </div>
      {isSessionList ? (
        ""
      ) : (
        <Fragment>
          <div className="row g-0" key={k + `-r12`}>
            <div className="col-12 px-1 text-start my-auto" key={k + `-c-name`}>
              <span>
                {input(
                  session?.comments || "",
                  "comments." + idx,
                  "textarea",
                  buttonFunction,
                  edit,
                  what
                )}
              </span>
            </div>
          </div>
          <div className="row px-2 g-0" key={k + `-r2`}>
            <div className="col col-12 text-end" key={k + `r2-c1-butt`}>
              {allCompleted
                ? ""
                : showTimerButton(
                    { func: showHideTimer },
                    "showTimer",
                    showTimer,
                    "exercise"
                  )}
              {exerciseLength > 0 &&
                completeAllButton(
                  { func: buttonFunction },
                  "exercise.all",
                  "exercise.completeAll",
                  allCompleted,
                  "exercise"
                )}
              {exerciseLength > 0 && showHideAll(buttonFunction)}
              {editSaveButtons(buttonFunction, session, edit)}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

SessionHeader.propTypes = {
  session: PropTypes.object,
  buttonFunction: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  showHideTimer: PropTypes.func,
  showTimer: PropTypes.bool,
  selected: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  exerciseLength: PropTypes.number,
  idx: PropTypes.number.isRequired,
  what: PropTypes.string.isRequired,
};
export default SessionHeader;

const compareOrder = (a, b, k) =>
  a.order < b.order ? -1 : a.order > b.order ? 1 : 0;

export const reOrder = (e, session) => {
  let exToMove = e.target.name.split(".").slice(-1);
  let exToMoveOrder = parseInt(exToMove);
  let move = e.target.value;
  let newExs = [...session.exercises];
  if (
    isNaN(exToMoveOrder) ||
    isNaN(move) ||
    !session?.exercises?.length ||
    (move === -1 && exToMoveOrder === 0) ||
    (move === 1 && exToMoveOrder === session?.exercises?.length - 1)
  )
    return newExs;

  newExs = order(newExs, exToMoveOrder, move);
  return newExs;
};

export const order = (objArray, idxToMove, move) => {
  objArray = objArray.map((obj, idx) => ({ ...obj, order: idx }));
  objArray = objArray.map((obj, idx) =>
    idx === idxToMove + move
      ? { ...obj, order: idxToMove }
      : idx === idxToMove
      ? { ...obj, order: idx + move }
      : obj
  );
  objArray = objArray.sort(compareOrder);
  return objArray;
};

export const addNewExercise = (session, exercise) => {
  let defaultSet = { no: 1, weight: 0, reps: 0, unit: "kg" };
  let defaultExercise = {
    name: exercise,
    order: (session?.exercises?.length || -1) + 1,
    object: "",
    type: "",
    sets: [],
  };
  defaultExercise.sets = [1, 2, 3].map((no) => ({ ...defaultSet, no: no }));
  let newSesh = { ...session };
  newSesh.exercises.push(defaultExercise);
  return newSesh;
};

export const deleteTheExercise = (exercises, no) => {
  if (exercises?.length && no) {
    let index = parseInt(no);
    exercises = exercises.filter((_, idx) => index !== idx);
  }
  return exercises;
};

export const addSet = (sets) => {
  let lastSetIdx = sets.length - 1;
  let lastSet =
    lastSetIdx === -1
      ? { no: 1, reps: 0, weight: 0, unit: "kg" }
      : { ...sets[lastSetIdx], no: lastSetIdx + 2 };
  sets.push(lastSet);
  return sets;
};

// deletes set from list
export const deleteSet = (sets, setId) => {
  sets.splice(setId, 1);
  sets = sets.map((set, i) => ({ ...set, no: i + 1 }));
  return sets;
};

export const updateSetFields = (sets, setId, setFld, value) => {
  let set = sets[setId];
  if (set?.link) {
    sets = sets.map((s) => (s?.link ? { ...s, [setFld]: value } : s));
  } else {
    sets[setId][setFld] = value;
  }
  return sets;
};

export const linkSets = (sets, linkOrUnlink) =>
  sets.map((s) => ({ ...s, link: linkOrUnlink }));

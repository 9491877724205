import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import SessionContext from "../../context/session/sessionContext";

const Pagingation = ({ sessionCount }) => {
  const sessionContext = useContext(SessionContext);
  const { getSessions } = sessionContext;

  const [currentPage, setCurrentPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // console.log(sessionCount);
  }, [sessionCount]);

  if (!sessionCount) return "";

  const paginationCalcs = (
    currentPage,
    pagesEachSide,
    totalItems,
    itemsPerPage
  ) => {
    let showHowManyPages = pagesEachSide * 2 + 1;
    let totalPages = Math.ceil(totalItems / itemsPerPage);

    let start;
    if (currentPage - pagesEachSide <= 1) {
      start = 1;
    } else if (totalPages <= showHowManyPages) {
      start = 1;
    } else if (currentPage + pagesEachSide >= totalPages) {
      start = totalPages - showHowManyPages + 1;
    } else {
      start = currentPage - pagesEachSide;
    }
    let pageArrayLength =
      totalPages < showHowManyPages ? totalPages : showHowManyPages;
    let showBeforePages =
      start > 1 && currentPage + pagesEachSide > showHowManyPages;
    let pages = Array.from({ length: pageArrayLength }, (e, i) => i + start);
    let showAfterPages = start + showHowManyPages <= totalPages;

    // console.log("-------------------------");
    // console.log({ currentPage, pagesEachSide, totalItems, itemsPerPage });
    // console.log({ showHowManyPages, totalPages });
    // console.log({ showBeforePages, pages, showAfterPages });

    return { showBeforePages, pages, showAfterPages };
  };

  let countPerPage = 10;
  let pagesEachSide = 2;

  let pagCounts = paginationCalcs(
    currentPage,
    pagesEachSide,
    sessionCount,
    countPerPage
  );
  let { showBeforePages, pages, showAfterPages } = pagCounts;

  const gotoPage = (e, p) => {
    setCurrentPage(p);
    getSessions({ start: p });
  };

  const gotoPageButton = (p, text, currentPage) => {
    let selected = currentPage === p;
    let selectedStyle = "text-primary h4";
    return selected ? (
      <span className={` ${selectedStyle} m-2`} key={`pagLink-${p}`}>
        {p}
      </span>
    ) : (
      <button
        className={`buttonAsLink m-2`}
        key={`pagLink-${p}`}
        onClick={(e) => gotoPage(e, p)}
      >
        {text}
      </button>
    );
  };

  const showPages = () => {
    return (
      <Fragment>
        {!showBeforePages
          ? ""
          : gotoPageButton(currentPage - pagesEachSide, "<<")}
        {pages.map((p) => gotoPageButton(p, p, currentPage))}
        {!showAfterPages
          ? ""
          : gotoPageButton(currentPage + pagesEachSide, ">>")}
      </Fragment>
    );
  };

  return <div>{showPages()}</div>;
};

Pagingation.propTypes = {
  sessionCount: PropTypes.number,
};

export default Pagingation;
